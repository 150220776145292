/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.default-font {
  font-family: 'Roboto', sans-serif;
}

.container-fluid {
  padding: 0px;
  overflow: hidden;
}
.border-primary {
  width: 200px;
  border-color: white !important;
}
.row {
  margin: auto;
}
.logo {
  max-width: 75%;
  max-height: 75%;

}
.logo-wrapper {
  position: absolute;
  width: 530px;
  height: auto;
  z-index: 999;
  left: 50%;
  top: 84px;
  transform: translateX(-50%);
}
.logo-v2 {
  position: relative;
  width: 100%;
  height: auto;
}
.banner {
  max-width: fit-content;
  max-height: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.icon {
  height:150px;
}

.double-line p{
  line-height: 2 ;
}

.v-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-blurb-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.v-blurb-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.oval-blurb-right {
  border-radius: 50px;
  background-color: #FFE8E7;
  padding:15px;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width:85%;
  height: 40%;
  z-index: -1;
  text-align: center;
}

.oval-blurb-right-mobile {
  border-radius: 50px;
  background-color: #FFE8E7;
  padding:15px;
  padding-right: 50px;
  position: absolute;
  top: 50%;
  left:65%;
  transform: translate(-50%, -50%);
  width:65%;
  height: 40%;
  z-index: -1;
  text-align: center;
}

.oval-blurb-right-middle {
  border-radius: 50px;
  background-color: #FFE8E7;
  padding:15px;
  padding-right: 50px;
  position: absolute;
  top: 50%;
  left:45%;
  transform: translate(-50%, -50%);
  width:65%;
  height: 40%;
  z-index: -1;
  text-align: center;
}

.oval-blurb-left {
  border-radius: 50px;
  background-color: #FFE8E7;
  padding:15px;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width:85%;
  height: 40%;
  z-index: -1;
}

.oval-blurb-left-mobile {
  border-radius: 50px;
  background-color: #FFE8E7;
  padding:15px;
  padding-left: 50px;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width:65%;
  height: 40%;
  z-index: -1;
  text-align: center;
}

.oval-blurb-left-middle {
  border-radius: 50px;
  background-color: #FFE8E7;
  padding:15px;
  padding-left: 50px;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  width:65%;
  height: 40%;
  z-index: -1;
  text-align: center;
}

.line-under {
  text-decoration: underline;
  font-size: large;
}

.btn-accordian {
  color: white;
}

.spacer-desktop {
  height: 75px;
}

.spacer-mobile {
  height: 25px;
}

.logo-back {
  background-color: white;
  border-bottom: #14408C solid 10px;
  height: 205px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.logo-back-mobile {
  background-color: white;
  border-bottom: #14408C solid 10px;
  height: 150px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bruce-banner-lg {
  text-align: center;
  height: 500px;
}

.bruce-banner-sm {
  text-align: center;
  height: 400px;
}

.middle-white-mobile {
  z-index:-1;
  position:relative;
  background-color: white;
  height: max-content;
  text-align:center;
  padding:25px;
  border-radius: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: bold;
  font-size: 18pt;
}
.cal-month-view .cal-open-day-events {
  background-color: #002159 !important;
}
.cal-month-view .cal-day-badge {
  background-color: #73CFA0 !important;
}
/* .btn-primary {
  background-color: #002159 !important;
  border-color: #002159 !important;
} */
.middle-white {
  z-index:-1;
  position:relative;
  background-color: white;
  height: max-content;
  text-align:center;
  padding:100px;
  border-radius: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.middle-white-bold {
  font-weight: bold;
  font-size: 18pt;
}

.middle-white-light {
  font-weight: normal;
  font-size: 14pt;
}

.cursor-hand {
  cursor: pointer;
}

.success-toast-class{
  background-color: #002159;
  position: relative;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  color: #FFFFFF;
  pointer-events: all;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
}
.error-toast-class{
  background-color: rgba(255,5,5,0.8);
  position: relative;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  color: #FFFFFF;
  pointer-events: all;cursor: pointer;
  text-align: center;
  border-radius: 5px;
}

a {
  color: #002159;
  background-color: transparent;
  text-decoration: none;
}
.no-right-gutter {
  padding-right: 0px;
}
.blue-header {
  height: 100px;
  background-color: #002159;
}
.coral-header {
  height: 85px;
  background-color: white;
  border-top: solid #73CFA0 12px;
  border-bottom: solid #73CFA0 12px;
}
.coral {
  background-color: white;
  color: #002159;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}
.bold-statement {
  color: #002159;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  line-height: 1.2;
}
.golden {
  color: #998D5C;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  line-height: 1.2;
}
.p-blurb {
  font-size: 24px;

}
.purple {
  background-color: #002159;
}
.modal-lg {

    max-width: 500px;

}
.modal-xl {

  max-width: 800px;

}
.croppedImg {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: 30% 30%;
}
.sm-croppedImg {
  width: 100%;
  height: auto;
}
.small-img-wrapper {
  width: 16.6666%;
}
.croppedImg2 {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: 30% 10%;
}
.blue {
  background-color: #002159;
  color: white !important;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  line-height: 1.2;
  padding-top: 45px;
  padding-bottom: 45px;
}

.carousel-caption {
    top: 50%;
    left: 35%;
    right: 35%;
    transform: translateY(-50%);
    max-width: 600px;
}
.carousel-header-text {
    font-size: 42px;
    font-weight: bold;
    color: white;
}
.carousel-paragraph-text {
  font-size: 24px;
  color: white;
}

.img-fluid {
  width:500px
}
.helper {
  display: inline-block !important;
  height: 100% !important;
  vertical-align: middle !important;
}
.fa-envelope {
  color: white;
  font-size: 40px;
}
.phone {
  font-size: 24px;
  color: white;
}
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border: lightgray solid 1px;
  background-color: white;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  height: calc(100vh - 800px) !important;
}
.mobile {
  display: none;
}
button {
  cursor: not-allowed;
}
img {
  pointer-events: none;
}

.contact-link {
  color: #002159;
  text-decoration: underline;
}

@media (max-width: 1000px) {


  .logo-wrapper {
    position: absolute;
    width: 350px;
    height: auto;
    top: 65px;
  }

  .blue-header {
    height: 75px;
    background-color: #002159;
  }
  .coral-header {
    height: 50px;
    border-width: 8px;
  }

  .coral {
    font-size: 45px;
  }

  .blue {
    font-size: 23px;
  }

  .bold-statement {
    font-size: 23px;
  }
  .golden {
    font-size: 23px;
  }

  .p-blurb {
    font-size: 18px;
  }

  .croppedImg {
    width: 100%;
    height: 300px;
  }

  .croppedImg2 {
    width: 100%;
    height: 300px;
  }
  .carousel-caption {
    top: 40%;
    transform: translateY(-40%);
    left: 15%;
    right: 15%;
}

  .carousel-header-text {
    font-size: 24px;
    font-weight: bold;
    color: white;
  }
  .carousel-paragraph-text {
    font-size: 14px;
    color: white;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    height: calc(100vh - 600px) !important;
  }

}

